<template>
  <b-card>
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-form
      ref="form"
      class="p-2"
    >
      <b-row>
        <b-col
          class="mb-4 d-flex justify-content-center align-items-center"
          cols="12"
        >
          <feather-icon
            icon="MapPinIcon"
            size="25"
            class="mr-2"
          />
          <h1>Modifica Sede</h1>
        </b-col>
        <b-col
          cols="12"
          class="text-center mb-5"
        >
          <b-button
            :variant="getColorByStatus(headquarterData.active)"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="toggleActive()"
          >
            <span>{{ getTextByStatus(headquarterData.active) }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col class="mb-2" cols="12" md="6" lg="6">
          <b-form-group label="Nome Sede" label-for="name">
            <b-form-input id="name" v-model="headquarterData.name" />
          </b-form-group>
        </b-col> -->
        <b-col
          class="mb-1"
          cols="12"
        >
          <b-img
            v-if="!mediaUrl"
            :src="headquarterData.image_path ? mediaEndpoint + headquarterData.image_path : ''"
            v-bind="headquarterData.image_path ? '' : blankProps"
            alt="Immagine Sede"
            class="headquarter_image"
          />
          <b-img
            v-if="mediaUrl"
            :src="mediaUrl"
            alt="Immagine Sede"
            class="headquarter_image"
          />
        </b-col>
        <b-col
          class="mb-5 text-center"
          cols="10"
        >
          <b-form-file
            v-model="mediaFile"
            :state="Boolean(mediaFile)"
            placeholder="Cambia Immagine scegliendo un File o trascinandolo qui..."
            drop-placeholder="Trascina il File qui..."
            accept="image/*"
            @input="previewMedia"
          />
        </b-col>
        <b-col
          class="mb-5"
          cols="2"
        >
          <b-button
            variant="outline-primary"
            @click="removePreview"
          >
            Rimuovi
          </b-button>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Numero di Telefono *"
            label-for="phone_number"
          >
            <b-form-input
              id="phone_number"
              v-model="headquarterData.phone_number"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
        >
          <b-form-group label="Indirizzo *">
            <map-input
              v-model="locationData"
              :edit="true"
              @input="setAddress($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-right mt-2"
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateHeadquarter"
          >
            <b-spinner
              v-if="loading"
              variant="light"
              small
            />
            <span v-if="!loading">Salva Cambiamenti</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="reset"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BLink,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MapInput from '@/views/apps/headquarters/MapInput.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BLink,
    BImg,
    MapInput,
    BSpinner,
  },
  props: {
    headquarterData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      locationData: {
        address: this.headquarterData.address_street,
        coordinates: {
          lat: parseFloat(
            this.headquarterData.address_coordinates ? this.headquarterData.address_coordinates.lat : 41.9,
          ),
          lng: parseFloat(
            this.headquarterData.address_coordinates ? this.headquarterData.address_coordinates.lng : 12.5,
          ),
        },
        zoom: 12,
      },
      blankProps: {
        blank: true,
        blankColor: '#777',
        height: 300,
      },
      loading: false,
      mediaFile: null,
      mediaUrl: null,
    }
  },
  computed: {
    mediaEndpoint() {
      return process.env.VUE_APP_MEDIA_URL
    },
  },
  methods: {
    getColorByStatus(active) {
      if (active) return 'danger'
      return 'success'
    },
    getTextByStatus(active) {
      if (active) return 'Disattiva la Sede'
      return 'Riattiva la Sede'
    },
    toggleActive() {
      const { id } = router.currentRoute.params
      let api = 'activate'
      if (this.headquarterData.active) api = 'deactivate'
      store
        .dispatch(`app-headquarters/${api}`, { id })
        .then(() => {
          router.replace({ name: 'apps-headquarters-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sede #${id} modificata con successo`,
                icon: 'MapPinIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    previewMedia() {
      if (this.mediaFile) this.mediaUrl = URL.createObjectURL(this.mediaFile)
    },
    removePreview() {
      URL.revokeObjectURL(this.mediaUrl)
      this.mediaUrl = null
      this.mediaFile = null
    },
    goBack() {
      this.$router.go(-1)
    },
    reset() {
      this.$refs.form.reset()
    },
    setAddress(event) {
      if (!event) return
      if (!event.address_components) return
      let street_number = null
      let route = null
      let locality = null
      let municipality = null
      let province = null
      let region = null
      let country = null
      let postal_code = null
      event.address_components.filter(el => {
        if (el.types[0] == 'street_number') street_number = el.short_name
        if (el.types[0] == 'route') route = el.short_name
        if (el.types[0] == 'locality') locality = el.short_name
        if (el.types[0] == 'administrative_area_level_3') municipality = el.short_name
        if (el.types[0] == 'administrative_area_level_2') province = el.short_name
        if (el.types[0] == 'administrative_area_level_1') region = el.short_name
        if (el.types[0] == 'country') country = el.short_name.substring(0, 2).toUpperCase()
        if (el.types[0] == 'postal_code') postal_code = el.short_name
      })
      if (street_number) {
        this.headquarterData.address_street = `${route}, ${street_number}`
      }
      if (!street_number) {
        this.headquarterData.address_street = route
      }
      this.headquarterData.address_cap = postal_code
      this.headquarterData.address_city = locality
      this.headquarterData.address_province = province
      this.headquarterData.address_nation_code = country
      this.headquarterData.address_coordinates = event.coordinates
    },
    updateHeadquarter() {
      const valid = this.validation()
      if (!valid) return
      this.loading = true
      const { id } = router.currentRoute.params
      const formData = new FormData()
      formData.append('phone_number', this.headquarterData.phone_number)
      formData.append('address_street', this.headquarterData.address_street)
      formData.append('address_cap', this.headquarterData.address_cap)
      formData.append('address_city', this.headquarterData.address_city)
      formData.append('address_province', this.headquarterData.address_province)
      formData.append('address_nation_code', this.headquarterData.address_nation_code)
      formData.append('address_coordinates', JSON.stringify(this.headquarterData.address_coordinates))
      if (this.mediaFile) formData.append('image', this.mediaFile)
      store
        .dispatch('app-headquarters/updateHeadquarter', { id, formData })
        .then(() => {
          router.replace({ name: 'apps-headquarters-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sede #${id} modificata con successo`,
                icon: 'MapPinIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
    },
    validation() {
      if (!this.headquarterData.phone_number) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Telefono',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Numero di Telefono per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.locationData) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Indirizzo',
            icon: 'AlertTriangleIcon',
            text: "Inserire l'Indirizzo della Sede per proseguire.",
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
.headquarter_image {
  width: 100%;
  max-height: 500px;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Sfoglia';
}
</style>
