<template>
  <component :is="headquarterData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="headquarterData === undefined">
      <h4 class="alert-heading">Errore dati Sede</h4>
      <div class="alert-body">
        Nessuna Sede trovata con questo ID. Controlla
        <b-link class="alert-link" :to="{ name: 'apps-headquarters-list' }"> Lista Sedi </b-link>
        per altre Sedi.
      </div>
    </b-alert>

    <template v-if="headquarterData" pills>
      <!-- Tab: Information -->
      <b-row>
        <b-col>
          <headquarters-edit-tab-information :headquarter-data="headquarterData" />
        </b-col>
      </b-row>
    </template>
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BCol, BRow } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import headquartersStoreModule from '../headquartersStoreModule'
import HeadquartersEditTabInformation from './HeadquartersEditTabInformation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BCol,
    BRow,

    HeadquartersEditTabInformation,
  },
  setup() {
    const toast = useToast()
    const headquarterData = ref(null)

    const HEADQUARTERS_APP_STORE_MODULE_NAME = 'app-headquarters'

    // Register module
    if (!store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(HEADQUARTERS_APP_STORE_MODULE_NAME, headquartersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME))
        store.unregisterModule(HEADQUARTERS_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('app-headquarters/fetchHeadquarter', { id: router.currentRoute.params.id })
      .then((response) => {
        headquarterData.value = response.data
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      headquarterData,
    }
  },
}
</script>

<style></style>
